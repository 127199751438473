<template>
    <mnSup mostrar="true" />
    <header class="headerInfo">
        <img src="assets/img/info-header.svg" alt="">
    </header>
    <main id="info">
        <div class="wrap">
            <div class="boxBtn">
                <img src="assets/img/info-ico-programa.svg" alt="" class="imgH">
                <a href="assets/pdf/CNE_Programa_Congreso_Nacional_2023.pdf" target="_blank">PROGRAMA</a>
            </div>
            <div class="boxBtn">
                <img src="assets/img/info-ico-buscador.svg" alt="" class="imgH">
                <button @click="goBuscador()">BUSCADOR DE PROYECTOS</button>
            </div>
            <div class="boxBtn">
                <img src="assets/img/info-ico-comite.svg" alt="" class="imgH">
                <a href="assets/pdf/COMITE_EVALUADOR.pdf" target="_blank">COMITÉ EVALUADOR</a>
            </div>
            <div class="boxBtn">
                <img src="assets/img/info-ico-libro.svg" alt="" class="imgH">
                <a href="https://www.explora.cl/wp-content/uploads/2023/03/2023_-EXPLORA_ILUSTRADO-WEB_MV_compressed.pdf" target="_blank">LIBRO ILUSTRADO</a>
            </div>
           </div>
        <div class="wrap">
            <p style="text-align:center; margin:auto;">Si requiere ayuda extra puede enviar un correo a: <a href="mailto:soportecongresoexplora@incode.cl">soportecongresoexplora@incode.cl</a></p>
        </div>
    </main>
    <footer class="footerInfo">
        <img src="assets/img/info-footer.svg" alt="">
    </footer>
    <footerLogo />

</template>

<script>
import mnSup from '@/components/mnSup.vue'
import footerLogo from '@/components/footerLogo.vue'
import modal from '@/components/modal.vue'
import {mapState, mapMutations} from 'vuex'

export default {
    name: 'Informaciones',
    components: {
        mnSup,footerLogo, modal
    },
    data(){
        return{
            mostrar: true,
            show:false
        }
    },
    created(){
        document.title = 'INFORMACIONES - XXI Congreso Nacional Explora'
    },
    methods:{
        ...mapMutations(['showModal']),
        goBuscador(){
            window.location.href = `/buscador`;
        }
    }
}
</script>

<style lang="scss" scoped>
header.headerInfo{
    padding:20px 40px;
}
main#info{
    .wrap{
        display: flex;
        gap:20px;
        margin-bottom: 50px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        max-width: 1250px;
        .boxBtn{
            width: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;
            max-width: 240px;
            img{
                width: 100px;
                height: auto;
                &.imgH{
                    width: auto;
                    height: 120px;
                }
            }
            button, a{
                background-color:#000;
                color:#fff;
                padding:15px 20px;
                border-radius: 50px;
                min-height: 60px;
                min-width: 200px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-family: 'Museo' , sans-serif;
                font-size: .9rem;
                &:hover{
                    background-color:rgb(49, 49, 49);
                }
            }
        }
    }
}
footer.footerInfo{
    padding: 0px 50px;
    text-align: right;
    img{
        width: 70%;
    }
    @media(max-width:1100px){
        padding: 0px 20px;
        text-align: center;
        img{
            width: 100%;
        }
    }
}


</style>